import { Container, Link, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Attribution = () => {
  const { t } = useTranslation();

  const translationAttribution = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {t('attribution.translatedBy')}{' '}
        <Link href={t('attribution.translatorLink')}>
          {t('attribution.translatorName')}
        </Link>
      </Typography>
    );
  };

  return (
    <Container>
      <Typography variant="body2" align='center' paddingTop={5}>
            <Box 
              sx={{
              verticalAlign:'center', 
              width: '100px',
              height: '78px',
              }}
              component="img"
              src="foundry.png"
              />  
           </Typography>
           <Typography variant="h6" align='center' paddingBottom='1px' >
              The Foundry
          </Typography>
            <Typography
              paddingBottom={3}
              margin={2}
              variant="body2"
              color="textSecondary"
              align="center"
            >          
              {t('attribution.createdBy')}{' '}
              <Link href="https://github.com/jhaals/yopass">Johan Haals</Link>
              {t('attribution.translatorName') && translationAttribution()}
            </Typography>
    </Container>
  );
};
