import { createTheme } from '@mui/material/styles';
// import { blueGrey } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0EA7B6',
    }
  },
});
